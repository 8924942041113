import { observable } from '@legendapp/state'
import { ToastChildren } from '@my/ui'
import { ReactNode } from 'react'
import { i18nInstance } from './i18n'

export const toast$ = observable({
  children: null as ReactNode | null,
})

export const showToast = (children: ReactNode) => {
  toast$.children.set(children)
}

export const showNetWorkErrToast = () => {
  toast$.children.set(
    <ToastChildren.Error
      text={
        i18nInstance.resolvedLanguage === 'en'
          ? 'request failed. please check your network'
          : '请求失败，请检查网络'
      }
    />
  )
}

export const showServerErrToast = () => {
  toast$.children.set(
    <ToastChildren.Error
      text={
        i18nInstance.resolvedLanguage === 'en'
          ? 'request failed, please try again later '
          : '请求失败，请稍后重试'
      }
    />
  )
}
