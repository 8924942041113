import { useMount } from '@legendapp/state/react'
import { ThemeProvider } from '@my/shadcn-ui'
import '@tamagui/core/reset.css'
import '@tamagui/font-inter/css/400.css'
import '@tamagui/font-inter/css/700.css'
import { NextThemeProvider, useRootTheme } from '@tamagui/next-theme'
import { Provider } from 'app/provider'
import { AuthProviderProps } from 'app/provider/auth'
import { api } from 'app/utils/api'
import { setStandardModelVersion } from 'app/utils/model-version'
import { NextPage } from 'next'
import Head from 'next/head'
import 'raf/polyfill'
import { ReactElement, ReactNode } from 'react'
import type { SolitoAppProps } from 'solito'
import '../public/web.css'

if (process.env.NODE_ENV === 'production') {
  require('../public/tamagui.css')
}

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

function MyApp({
  Component,
  pageProps,
}: SolitoAppProps<{ initialSession: AuthProviderProps['initialSession'] }>) {
  // reference: https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts
  const getLayout = Component.getLayout || ((page) => page)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_theme, setTheme] = useRootTheme()
  useMount(() => {
    setStandardModelVersion()
  })

  return (
    <>
      <Head>
        <title>MOGG AI</title>
        <meta name="description" content="Generate AI Starter" />
        <link rel="icon" href="/favicon.svg" />
      </Head>
      <NextThemeProvider forcedTheme="dark" enableSystem={false} defaultTheme="dark">
        <ThemeProvider attribute="class" defaultTheme="dark">
          <Provider initialSession={pageProps.initialSession}>
            {getLayout(<Component {...pageProps} />)}
          </Provider>
        </ThemeProvider>
      </NextThemeProvider>
    </>
  )
}

export default api.withTRPC(MyApp)
