import { useObserve } from '@legendapp/state/react'
import { CustomToast, ToastProvider as ToastProviderOG, useToastController } from '@my/ui'
import { toast$ } from 'app/utils/toast'
import { Toaster } from 'sonner'

import { ToastViewport, ToastViewportProps } from './ToastViewport'

export const ToastProvider = ({
  children,
  ...viewportProps
}: { children: React.ReactNode } & ToastViewportProps) => {
  return (
    <ToastProviderOG
      swipeDirection="up"
      swipeThreshold={20}
      duration={3000}
      native={
        [
          /* uncomment the next line to do native toasts on mobile - note that it won't be as customizable as custom toasts, especially on android */
          // 'mobile'
        ]
      }
    >
      {children}
      <ToastViewport {...viewportProps} />
      <CustomToast />
      <ToastEventWatcher />
      <Toaster position="top-right" richColors duration={3000} closeButton />
    </ToastProviderOG>
  )
}

const ToastEventWatcher = () => {
  const toast = useToastController()
  useObserve(() => {
    const children = toast$.children.get()
    children &&
      toast.show('', {
        children,
      })
  })
  return <></>
}
