import type { AppRouter } from '@my/api'
import { TRPCLink, httpBatchLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { observable } from '@trpc/server/observable'
import NextRouter from 'next/router'
import SuperJSON from 'superjson'

import { getDeviceId } from './deviceId'
import { getBaseUrl } from './getBaseUrl'
import { showNetWorkErrToast, showServerErrToast } from './toast'

const protectedRoutes = ['/profile', '/settings', '/billings']

export const customLink: TRPCLink<AppRouter> = () => {
  // here we just got initialized in the app - this happens once per app
  // useful for storing cache for instance
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          //console.log("we received value", value);
          observer.next(value)
        },
        error(err) {
          // console.log("we received error", err);
          observer.error(err)
          if (!err) {
            showNetWorkErrToast()
          } else if (
            err?.data?.code === 'UNAUTHORIZED' &&
            protectedRoutes.some((route) => location.pathname.includes(route))
          ) {
            NextRouter.push('/sign-in')
          } else {
            showServerErrToast()
          }
        },
        complete() {
          observer.complete()
        },
      })
      return unsubscribe
    })
  }
}

export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      queryClientConfig: {
        // web query config
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      },
      transformer: SuperJSON,
      links: [
        customLink,
        httpBatchLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           **/
          url: `${getBaseUrl()}/api/trpc`,

          // You can pass any HTTP headers you wish here
          async headers() {
            return {
              device: getDeviceId(),
            }
          },
        }),
      ],
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
})
