import { init } from '@paralleldrive/cuid2'

export function formatNumber2Str(num: number) {
  return num >= 1e3
    ? Math.round(num / 1e3) + 'k+'
    : num >= 1e2
    ? Math.round(num / 1e2) + '00+'
    : num
}

export const genRandomId = () => Math.random().toString(36).slice(2, 10)

export function guid() {
  function _p8(s: boolean = false) {
    const p = (Math.random().toString(16) + '000000000').substr(2, 8)
    return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p
  }
  return _p8() + _p8(true) + _p8(true) + _p8()
}

export const isUUID = (str: string) => {
  return /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i.test(str)
}

export const createUserReferrerId = init({
  random: Math.random,
  length: 10,
  fingerprint: 'mydreamboy-inviter-fingerprint',
})

export const getUserLeftCredits = ({
  addon_credits,
  addon_credits_used,
  subscription_credits,
  subscription_credits_used,
}: {
  addon_credits: string | number
  subscription_credits: string | number
  addon_credits_used: string | number
  subscription_credits_used: string | number
}) => {
  const leftCount = Number(
    Number(addon_credits) +
      Number(subscription_credits) -
      Number(addon_credits_used) -
      Number(subscription_credits_used)
  )
  return Number.isInteger(leftCount) ? leftCount : Number(leftCount).toFixed(2)
}
