import Cookies from 'js-cookie'
import { guid } from './number'

const COOKIE_DEVICE_KEY = 'mogg_device_id'

export const getDeviceId = () => {
  const deviceIdInCookie = Cookies.get(COOKIE_DEVICE_KEY)
  if (deviceIdInCookie) return deviceIdInCookie
  else {
    const deviceId = guid()
    Cookies.set(COOKIE_DEVICE_KEY, deviceId)
    return deviceId
  }
}
