import { createAnimations } from '@tamagui/animations-moti'
import { Easing } from 'react-native-reanimated'

export const animations = createAnimations({
  '100ms': {
    type: 'timing',
    duration: 100,
  },
  '200ms': {
    type: 'timing',
    duration: 200,
  },
  '1000ms': {
    type: 'timing',
    duration: 1000,
    easing: Easing.linear
  },
  '2000ms': {
    type: 'timing',
    duration: 2000,
    easing: Easing.linear

  },
  '10000ms': {
    type: 'timing',
    duration: 10000,
    easing: Easing.linear

  },
  bouncy: {
    type: 'spring',
    damping: 10,
    mass: 0.9,
    stiffness: 100,
  },
  lazy: {
    type: 'spring',
    damping: 20,
    stiffness: 60,
  },
  quick: {
    type: 'spring',
    damping: 20,
    mass: 1.2,
    stiffness: 250,
  },
  medium: {
    damping: 15,
    stiffness: 120,
    mass: 1,
  },
  slow: {
    damping: 15,
    stiffness: 40,
  },
  tooltip: {
    damping: 10,
    mass: 0.9,
    stiffness: 100,
  },
})
