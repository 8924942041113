import { useState, useEffect } from 'react'

import { Input, InputProps } from './ui/input'

export const EnterControlInput = ({
  onEnter,
  ...inputProps
}: InputProps & { onEnter?: (v: string) => void }) => {
  const [value, setValue] = useState('')
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        onEnter?.(value)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [onEnter, value])
  return <Input value={value} onChange={(e) => setValue(e.target.value)} {...inputProps} />
}
