import { Check, X } from '@tamagui/lucide-icons'
import { Paragraph, Spinner, XStack, YStack, withStaticProperties } from 'tamagui'

export type ToastChildrenProps = {}

const Wrapper: React.FC<ToastChildrenProps> = (props) => {
  return <YStack>{/* */}</YStack>
}

const Success = ({ text }: { text: string }) => {
  return (
    <XStack gap="$3" ai="center">
      <XStack borderRadius="$10" p="$1.5" backgroundColor="$green11">
        <Check size={12} strokeWidth={2.5} />
      </XStack>
      <Paragraph fow="700">{text}</Paragraph>
    </XStack>
  )
}

const Error = ({ text }: { text: string }) => {
  return (
    <XStack gap="$3" ai="center">
      <XStack borderRadius="$10" p="$1.5" backgroundColor="$red11">
        <X size={12} strokeWidth={2.5} />
      </XStack>
      <Paragraph fow="700">{text}</Paragraph>
    </XStack>
  )
}
const Loading = ({ text }: { text: string }) => {
  return (
    <XStack gap="$3" ai="center">
      <Spinner color="$color" />
      <Paragraph fow="700">{text}</Paragraph>
    </XStack>
  )
}

export const ToastChildren = withStaticProperties(Wrapper, {
  Success,
  Error,
  Loading,
})
