import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'

const historyManagerContext = createContext<ReturnType<typeof useHistoryManager>>({
  history: [],
  canGoBack: false,
})

export const HistoryManagerProvider = ({ children }: { children: React.ReactNode }) => {
  const historyManager = useHistoryManager()

  return (
    <historyManagerContext.Provider value={historyManager}>
      {children}
    </historyManagerContext.Provider>
  )
}

export const useHistory = () => useContext(historyManagerContext)

function useHistoryManager() {
  const router = useRouter()
  const [history, setHistory] = useState<string[]>([])

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (!shallow) {
        setHistory((prevState) => [...prevState, url])
      }
    }

    router.beforePopState(() => {
      setHistory((prevState) => prevState.slice(0, -2))
      return true
    })

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  return { history, canGoBack: history.length > 1 }
}
