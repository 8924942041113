import { i18nInstance } from 'app/utils/i18n'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

let initialRender = false

export const I18nProvider = ({ children }: { children: React.ReactNode }) => {
  const { locale } = useRouter()
  if (typeof window === 'undefined' || !initialRender) {
    initialRender = true
    i18nInstance.changeLanguage(locale)
  }

  useEffect(() => {
    i18nInstance.changeLanguage(locale)
  }, [locale])
  return <>{children}</>
}
