import { ChevronRightIcon } from '@radix-ui/react-icons'
import Link from 'next/link'
import React from 'react'

import { cn } from '../lib/utils'

export type BreadCrumbType = {
  title: string
  link: string
}

type BreadCrumbPropsType = {
  items: BreadCrumbType[]
}

export function BreadCrumb({ items }: BreadCrumbPropsType) {
  return (
    <div className="mb-4 flex items-center space-x-1 text-sm text-muted-foreground">
      {/* <Link href="/dashboard" className="overflow-hidden text-ellipsis whitespace-nowrap">
        Dashboard
      </Link> */}
      {items?.map((item: BreadCrumbType, index: number) => (
        <React.Fragment key={item.title}>
          {index > 0 && <ChevronRightIcon className="h-4 w-4" />}
          <Link
            href={item.link}
            className={cn(
              'font-medium',
              index === items.length - 1
                ? 'text-foreground pointer-events-none'
                : 'text-muted-foreground'
            )}
          >
            {item.title}
          </Link>
        </React.Fragment>
      ))}
    </div>
  )
}
