import { ToastViewport as ToastViewportOg, YStack, isWeb, styled } from '@my/ui'

export interface ToastViewportProps {
  noSafeArea?: boolean
}
// fix toast overlap on dialog
const ToastViewportWrapperFrame = styled(YStack, {
  name: 'ViewportWrapper',

  variants: {
    unstyled: {
      false: {
        pointerEvents: 'box-none',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: isWeb ? ('fixed' as any) : 'absolute',
        maxWidth: '100%',
        tabIndex: 0,
        zIndex: 99999999,
      },
    },
  } as const,

  defaultVariants: {
    unstyled: false,
  },
})
export const ToastViewport = ({ noSafeArea }: ToastViewportProps) => {
  return (
    <ToastViewportWrapperFrame>
      <ToastViewportOg
        left={noSafeArea ? 0 : 10}
        right={noSafeArea ? 0 : 10}
        top={noSafeArea ? 0 : 10}
      />
    </ToastViewportWrapperFrame>
  )
}
